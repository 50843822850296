<template>
    <div class="zch-share">
        <div class="modal">
            <div class="desktop">
                <h1>Now help your friends and family calculate zakat accurately.</h1>
                <div class="buttons">
                    <h2>Share with your friends and family!</h2>
                    <div class="row">
                        <div class="copy">
                            <input type="text" readonly placeholder="zakchat.com/" />
                            <span @click="copyLink()">Copy</span>
                        </div>
                        <div class="media">
                            <social-sharing
                                class="items"
                                url="https://zakchat.com"
                                title="Let ZAK the robot work out your Zakat"
                                description="I just paid my Zakat accurately, thanks to ZAKchat! To calculate yours, chat to ZAK here"
                                quote="I just paid my Zakat accurately, thanks to ZAKchat! To calculate yours, chat to ZAK here"
                                hashtags="zakchat,zakat"
                                :networks="overriddenNetworks"
                                inline-template
                            >
                                <div class="zch-share-items">
                                    <network network="email">
                                        <img src="../assets/share-icons/email.svg" alt="" />
                                    </network>
                                    <network network="facebook">
                                        <img src="../assets/share-icons/messenger.svg" alt="" />
                                    </network>
                                    <network network="twitter">
                                        <img src="../assets/share-icons/twitter.svg" alt="" />
                                    </network>
                                    <network network="whatsapp">
                                        <img src="../assets/share-icons/whatsapp.svg" alt="" />
                                    </network>
                                </div>
                            </social-sharing>
                        </div>
                    </div>
                </div>
            </div>
            <p>Now help your friends and family calculate zakat accurately.</p>
            <button @click="viewShareItems">SHARE</button>
            <div class="success-notice">
                <img src="../assets/success-share.svg" alt="" />
                <span>You’ve paid the correct zakat!</span>
            </div>
        </div>
        <div class="mobile-menu" v-if="viewingShare">
            <social-sharing
                class="items phone"
                url="https://zakchat.com"
                title="Let ZAK the robot work out your Zakat"
                description="I just paid my Zakat accurately, thanks to ZAKchat! To calculate yours, chat to ZAK here"
                quote="I just paid my Zakat accurately, thanks to ZAKchat! To calculate yours, chat to ZAK here"
                hashtags="zakchat,zakat"
                :networks="overriddenNetworks"
                inline-template
            >
                <div class="zch-share-items">
                    <network network="email">
                        <div>Email a friend</div>
                        <img src="../assets/share-icons/phone/email.svg" alt="" />
                    </network>
                    <network network="facebook">
                        <div>Post on Facebook</div>
                        <img src="../assets/share-icons/phone/facebook.png" alt="" />
                    </network>
                    <network network="messenger">
                        <div>Send on Messenger</div>
                        <img src="../assets/share-icons/phone/messenger.svg" alt="" />
                    </network>
                    <network network="whatsapp">
                        <div>Share on Whatsapp</div>
                        <img src="../assets/share-icons/phone/whatsapp.svg" alt="" />
                    </network>
                </div>
            </social-sharing>
        </div>
    </div>
</template>

<script>
export default {
    name: "Share",
    data() {
        return {
            copiedText: `Calculate Zakat with help of ZAK: https://zakchat.com`,
            overriddenNetworks: {
                copy: {
                    sharer: "",
                    type: "",
                },
            },
            viewingShare: false,
        };
    },
    methods: {
        copyLink() {
            const body = document.getElementsByTagName("body")[0],
                tempInput = document.createElement("INPUT");

            body.appendChild(tempInput);
            tempInput.setAttribute("value", this.copiedText);
            tempInput.select();
            document.execCommand("copy");

            body.removeChild(tempInput);
        },
        viewShareItems() {
            this.viewingShare = true;
        },
    },
};
</script>

<style lang="scss">
.zch-share {
    width: 100%;
    height: 100%;
    padding: 0;
    background: url("../assets/share-mobile.png");
    background-size: cover;

    @media screen and (min-width: 576px) {
        background: url("../assets/share-desktop.png");
        background-size: cover;
    }

    @media screen and (min-width: 325px) {
        background-size: contain;
    }

    @media screen and (min-width: 992px) {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
    }

    .modal {
        height: 47%;
        width: 100%;
        padding: 40px 10px 65px 10px;
        position: absolute;
        bottom: 0;
        border-radius: 14px 14px 0 0;
        background-color: #ffffff;

        @media screen and (min-width: 992px) {
            height: auto;
            position: initial;
            border-radius: 14px;
            padding: 100px;
            width: calc(80% - 200px);
        }

        @media screen and (max-width: 325px) {
            height: 60%;
        }

        .desktop {
            display: none;
            @media screen and (min-width: 992px) {
                display: flex;
                flex-direction: column;
            }

            h1 {
                margin: 0;
                font-family: Muli, sans-serif;
                font-size: 42px;
                font-weight: 800;
                line-height: 1.17;
                color: $color-cod-gray;
            }
            h2 {
                margin: 0;
            }

            .buttons {
                h2 {
                    font-family: Muli, sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.86;
                    color: $color-cod-gray;
                    margin-top: 46px;
                }
                .row {
                    display: flex;
                    justify-content: space-between;
                    .copy {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 60%;
                        border-radius: 50px;
                        border: solid 1px #e5e5e5;
                        background-color: #ffffff;
                        padding: 2px 21px;
                        margin-right: 10px;
                        max-height: 53px;

                        input {
                            background: #ffffff;
                            font-family: Muli, sans-serif;
                            font-size: 16px;
                            line-height: 1.95;
                            color: $color-cod-gray;
                            border: none;
                            padding: 0;
                            max-height: 53px;
                        }

                        span {
                            font-family: Muli, sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 1.95;
                            color: $color-montecarlo;
                            cursor: pointer;
                            transition: opacity 500ms;

                            &:hover {
                                opacity: 0.6;
                            }
                        }
                    }
                    .media {
                        .items {
                            width: max-content;
                            span {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        p {
            font-family: Muli, sans-serif;
            font-size: 21px;
            line-height: 1.52;
            color: $color-cod-gray;
            text-align: center;

            @media screen and (min-width: 992px) {
                display: none;
            }
        }

        button {
            padding: 11px 46px;
            border: 2px solid $color-blackberry;
            text-align: center;
            border-radius: 31.7px;
            background-color: $color-blackberry;
            font-family: Muli, sans-serif;
            font-size: 19px;
            font-weight: 800;
            letter-spacing: 0.81px;
            color: #ffffff;
            cursor: pointer;
            transition: 500ms;
            width: 100%;

            &:hover {
                background-color: #{$color-blackberry}cc;
            }

            @media screen and (min-width: 992px) {
                display: none;
            }
        }

        .success-notice {
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                margin-left: 10px;
                font-family: Muli, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.11;
                color: $color-blackberry;
            }

            @media screen and (min-width: 992px) {
                justify-content: flex-start;
            }
        }
    }

    .mobile-menu {
        display: block;
        background-color: #fff;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .items.phone {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 28px;

        span {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 24px 0;
            border-bottom: solid 1px #dfdfdf;
            justify-content: space-between;
            cursor: pointer;
            transition: opacity 500ms;

            &:hover {
                opacity: 0.6;
            }

            div {
                font-family: Muli, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.67;
                color: $color-cod-gray;
            }
        }
    }
}
</style>
